import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import BackgroundMedia from "../../molecules/BackgroundMedia"
import MediaTextBlock, { MediaTextBlockLayout } from "../../molecules/tileable-blocks/MediaTextBlock"
import DatoStructuredTextBlock from "../DatoStructuredTextBlock"

const DatoMediaWithText = (props: Queries.DatoMediaWithTextFragment) => {
  //console.log("Rendering DatoMediaWithText", props)

  const { layout = "Tile", textBackground = "BrandWhite" } = props

  return (
    <MediaTextBlock
      id={slugify(props.title)}
      layout={layout as MediaTextBlockLayout}
      contentBackground={textBackground as HarviaBackgroundColor}
      media={<BackgroundMedia {...props.media} />}
      content={
        <>
          {props.title && <h2>{props.title}</h2>}
          <DatoStructuredTextBlock data={props.content} shiftHeadings={1} />
        </>
      }
    />
  )
}

export default DatoMediaWithText

export const query = graphql`
  fragment DatoMediaWithText on DatoCmsMediaWithText {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    media {
      ...DatoMedia
    }
    textBackground
    layout
    title
    content {
      value
      links {
        __typename
        ... on DatoCmsRouteLink {
          ...DatoRouteLink
        }
        ... on DatoCmsExternalUrl {
          ...DatoExternalUrl
        }
        ... on DatoCmsArticleLink {
          ...DatoArticleLink
        }
        ... on DatoCmsProductLink {
          ...DatoProductLink
        }
        ... on DatoCmsProductFinderLink {
          ...DatoProductFinderLink
        }
      }
    }
  }
`
